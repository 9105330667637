import React, {useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import lofiImage from '../component/lofiimage.jpg';
import musicCard from '../component/musicard.png';
import wubbyImage from '../component/bot2.png';
import userImage from  '../component/userphoto.png';
import peerstachio from '../component/peerstachio.jpg';

import svgLineVector from '../component/LineVector.svg';
import Particles from 'react-particles-js';
import guilleon from '../component/Guilleon-01.png';
import lofiVideo from '../component/lofi.mp4';
import anime from 'animejs/lib/anime.es.js';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import WebIcon from '@material-ui/icons/Web';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import ProjectCard from "./ProjectCard";
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
       
        // Landing Page div

        landingPageDiv: {
            position: "relative",
            minWidth: 1440,
            height: 2600,
            background: "#F1F1F1"
        },

        // Navbar div
        navBarDiv: {
            position: "relative",
            height: 100,
            left: 0,
            right: 0,
            top: 0
        },

        // Nav div
        navDiv: {
            position: "absolute",
            minWidth: 1110,
            height: 33,
            left: 165,
            top: 33
        },

        // Nav div name
        navDivName: {
            position: "absolute",
      
            height: 33,
            left: 0,
            top: 0,

            color: "#151517",

            fontSize: 22,

            fontFamily: "Poppins",
            fontStyle: "normal",
            
    
        },

        // firstDiv 
        firstDiv: {
            position: "absolute",
            minWidth: 1110,
            height: 480,
            left: 165,
            right: 196
        },

        // developer div
        developerDiv: {
            position: "absolute",
            minWidth: 501,
            height: 480,
            left: 0,
            top: 0
        },

        // hey

        heyH4: {
            position: "absolute",
            width: 54,
            height: 36,
            left: 0,
            top: 0,

            color: "#828282",
            fontSize: 24,
            fontFamily: "Poppins",
            fontStyle: "normal",
            
        },

        // Devloper Tag Web
        webName: {
            position: "absolute",
            width: 501,
            height: 124,
            left: 0,
            top: 87,

            fontSize:  48,

            fontFamily: "Poppins",
            fontStyle: "normal",

    
        },
        // Dev text

        textDev: {
            position: "absolute",
            width: 425,
            height: 30,
            left: 0,
            top: 320,
            
            fontSize: 18,
            fontFamily: "Poppins",
            fontStyle: "normal",


        },
        nameColor: {
            color: "#7B0BA9"
        },

        svgLineVector: {
            position: "absolute",
            width: 324,
            height: 19,
            left: 101,
            top: 226,
         
        },
        // Contact me button
        contactButton:{
            position: "absolute",
            width: 173,
            height: 55,
            left: 0,
            top: 425,

            background: "#7B0BA9",
            borderRadius: 10
        },

        contactText: {
            fontSize: 16,
            color: "#FFFFFF"
        },
        heroDiv: {
            position: "absolute",
            width: 559,
            height: 473.44,
            left: 551,
            top: 7
        },
        heroImage:{
            position: "absolute",

            height: 458,
            left: 15, top: 0,
            borderRadius: 1000
        },

        videoDiv: {
            position: "absolute",
            width: 1440,
            height: 833,
            top: 868,
            left: 0
        },

        gladtoDiv:{
            position: "absolute",
            width: 590.66,
            height: 87.4,
            left: 460.47,
            top: 1030,

            fontSize: 67.665,
            fontFamily: "Poppins",
            fontStyle: "normal",
            color: "#FFFFFF"
        },

        gladtoParagraph: {
            position: "absolute",
            width: 892,
            height: 383.09,
            left: 309,
            top: 1157.43,

            fontSize: 22.7015,
            fontFamily: "Poppins",
            fontStyle: "normal",
            color: "#FFFFFF",
            fontWeight: "normal",
            textAlign: "center",
            lineHeight: "43px"
        },

        projectDiv: {
            position: "absolute",
            width: 1109,
            height: 692.67,
            left: 165,
            top: 1893,
            fontSize: "48px",
            lineHeight: "62px",
            fontWeight: 600,
            fontFamily: "Poppins",
            fontStyle: "normal",
        },

        authorCardContainer: {
            display: "flex",
            justifyContent: "center"
        },

        authorCard: {
            maxWidth: 400,

        },
        authorTitle: {
            display: "flex",
            justifyContent: "center"
        },
        authorImage: {
            width: "100%",
        },
        projectContainr: {
            color: "white",
        },
        projectCard:{
            maxWidth: 350
        }



    }));

function LandingPage() {
    const classes = useStyles();

    return(
        <div className={classes.landingPageDiv}>

            <div className={classes.navBarDiv}>
                <div className={classes.navDiv}>
                    <h1 className={classes.navDivName}> Cristian Guillen</h1>
                </div>
            </div>

            <div className={classes.firstDiv}>



                <div className={classes.developerDiv}>
                    <h4 className={classes.heyH4}>
                        Hey!
                    </h4>

                    <h1 className={classes.webName}>
                        I'm <span className={classes.nameColor}>Cristian Guillen.</span> <br/>A Web Developer.
                    </h1>

                    <p className={classes.textDev}>
                        A developer, lover of coffee, and a Geek for NLP!
                    </p>

                    <svg className={classes.svgLineVector} width="334" height="28" viewBox="0 0 334 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path d="M196.589 8.17545C123.307 8.17545 46.4668 15.1131 11.3839 19.2259C7.99201 19.6235 5 16.9817 5 13.5666C5 10.5455 7.34036 8.05539 10.3576 7.90294C44.9357 6.15576 116.001 2.77231 162.562 1.27779C210.757 -0.269192 290.905 5.01447 326.977 8.00619C328.139 8.10256 329 9.07177 329 10.2378C329 11.5259 327.92 12.5504 326.635 12.4645C309.579 11.3251 289.902 8.17545 196.589 8.17545Z" fill="#BA035F"/>
<path d="M196.589 8.17545C123.307 8.17545 46.4668 15.1131 11.3839 19.2259C7.99201 19.6235 5 16.9817 5 13.5666C5 10.5455 7.34036 8.05539 10.3576 7.90294C44.9357 6.15576 116.001 2.77231 162.562 1.27779C210.757 -0.269192 290.905 5.01447 326.977 8.00619C328.139 8.10256 329 9.07177 329 10.2378C329 11.5259 327.92 12.5504 326.635 12.4645C309.579 11.3251 289.902 8.17545 196.589 8.17545Z" stroke="black"/>
</g>
<defs>
<filter id="filter0_d" x="0.5" y="0.500031" width="333" height="27.2656" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>

                    <button className={classes.contactButton}>
                        <p className={classes.contactText}><a href = "mailto: guillencristian8@gmail.com"> Contact Me</a></p>
                    </button>

                </div>

                <div className={classes.heroDiv}>
                    <img className={classes.heroImage}src={userImage}>
                    </img>
                </div>

            </div>

            <video  autoPlay={true} className={classes.videoDiv}>
                <source src={lofiVideo} type="video/mp4"/>
            </video>

            <h1 className={classes.gladtoDiv}>
                Glad to Help You!
            </h1>

            <p className={classes.gladtoParagraph}>
            Hello! My name is Cristian Guillen, I graduated from University of Michigan and I really love working on projects that are new and have a positive impact in society. I really love NLP since I'm fascinated by how machines can predict language patterns and use them to act human like. Currently I'm building a Youtube channel with tech related projects and working in front end development. Looking into learning Scala and seeing what kind of projects I can do with that langauge. Reach out if you want to get in contact with me or want to ask anything tech related.
            </p>

            <div className={classes.projectDiv}>
                <h1>
                    Recent Projects
                </h1>

                <Grid item lg={12} md={12} xs={12} className={classes.projectContainr}>


<Grid container justify="center" spacing={1}>
    <Grid key="1" item >
        <Card className={classes.projectCard}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Simple-Music-Interaction-Card
                </Typography>
                <Typography variant="body2" gutterBottom>
                    A ReactJS frontend UI for a music cards.
                </Typography>
            </CardContent>

            <CardActions>
                <IconButton href="https://github.com/FuzedxPheonix/Simple-Music-Interaction-Card">
                    <GitHubIcon />
                </IconButton>
                <IconButton href="https://www.youtube.com/watch?v=-Pg81vzV2Qw">
                    <YouTubeIcon/>
                </IconButton>
            </CardActions>
        </Card>
    </Grid>

    <Grid key="2" item>
        <Card className={classes.projectCard} >
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Neural Net Bot Project
                </Typography>
                <Typography variant="body2" gutterBottom>
                    A text to enter chat bot based on a Twitch Streamer's followers chat. It predicts the responses it would give to the
                    streamer based on what chat is currently saying.
                </Typography>
            </CardContent>

            <CardActions>
                <IconButton href="https://youtu.be/ju-Einp2-cI">
                    <YouTubeIcon/>
                </IconButton>
            </CardActions>
        </Card>

    </Grid>


    <Grid key="3" item>
        <Card className={classes.projectCard}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Calculator UI
                </Typography>
                <Typography variant="body2" gutterBottom>
                   Created a ReactJS calculator UI. I created this from scratch and there is a Youtube tutorial.
                </Typography>
            </CardContent>

            <CardActions>
                <IconButton href="https://github.com/FuzedxPheonix/materialui-calculator">
                    <GitHubIcon />
                </IconButton>

                <IconButton href="https://www.youtube.com/embed/duiLKs5SEd8">
                    <YouTubeIcon/>
                </IconButton>
            </CardActions>
        </Card>
    </Grid>

    <Grid key="4" item>
        <Card className={classes.projectCard}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Peerstachio
                </Typography>
                <Typography variant="body2" gutterBottom>
                    This is a web application that we built using Python Django and JQuery. It was a startup company that had a functioning website and algorithms that predicted the students best mentor. The project is found in Github and it's open source now so hopefully someone else can continue the work.
                </Typography>
            </CardContent>

            <CardActions>
                <IconButton href="https://github.com/FuzedxPheonix/peerstachio-opensource">
                    <GitHubIcon />
                </IconButton>

                <IconButton href="https://www.youtube.com/embed/duiLKs5SEd8">
                    <YouTubeIcon/>
                </IconButton>
            </CardActions>
        </Card>
    </Grid>

    <Grid key="5" item>
        <Card className={classes.projectCard}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    React Leaflet Draw Library Tutorial


                </Typography>
                <Typography variant="body2" gutterBottom>
                    A project that allows ReactJS to have React-Leaflet be used using Typescript.
                </Typography>
            </CardContent>

            <CardActions>
                <IconButton href="https://github.com/FuzedxPheonix/react-typescript-leaflet-draw-example">
                    <GitHubIcon />
                </IconButton>

                <IconButton href="https://youtu.be/WCWE9-4XF8Y">
                    <YouTubeIcon/>
                </IconButton>
            </CardActions>
        </Card>
    </Grid>

    <Grid key="6" item>
        <Card className={classes.projectCard}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Guilleon


                </Typography>
                <Typography variant="body2" gutterBottom>
                    This is website that was styled using Square Space. I'm currently helping my father start his small painting company since he always wanted to start one. I love building tech but generally building is something I am enthusiastic about.
                </Typography>
            </CardContent>

            <CardActions>
                <IconButton href="https://www.guilleon.com/">
                    <WebIcon />
                </IconButton>
            </CardActions>
        </Card>
    </Grid>
    </Grid>




</Grid>

            </div>



        </div>
        )
}

export default LandingPage;