import React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import LandingPage from "./component/LandingPage";


function App() {
  return (
      <Router>
        <Route path="/" exact component={LandingPage}></Route>
      </Router>
  );
}

export default App;
